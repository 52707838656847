import ForgotPassword from 'components/ForgotPassword';
import React from 'react';

const Forgot = () => {
  return (
    <main>
      <ForgotPassword />
    </main>
  );
};

export default Forgot;
