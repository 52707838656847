import React from 'react';
import Directory from '../components/Directory/index';

const Mentors = () => {
  return (
    <main>
      <Directory />
    </main>
  );
};

export default Mentors;
