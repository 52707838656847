import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  settingsWrapper: {
    margin: '0px auto',
    paddingTop: '60px',
    paddingBottom: '80px',
  },
  title: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '28px',
    lineHeight: '38px',
    color: '#495057',
  },
  insideWrapper: {
    display: 'grid',
    gridTemplateColumns: ({ isMobile }) => (isMobile ? '1fr' : '0.25fr 0.75fr'),
    gap: '20px',
  },
  accountInfoWrapper: {
    padding: '38px 25px',
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  mentorName: {
    height: '54px',
    width: '54px',
    background: '#FFAB00',
    borderRadius: '50%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '21px',
    lineHeight: '29px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mentorProfession: {
    marginTop: 0,
    marginBottom: 0,
  },
  mentorFullName: {
    marginTop: 0,
    marginBottom: 0,
  },
  batch: {
    background: '#026FE4',
    border: '2px solid #026FE4',
    boxSizing: 'border-box',
    borderRadius: '30px',
    marginTop: '25px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '150%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '80px',
  },
  email_password: {
    marginTop: '25px',
    '& p': {
      margin: 0,
    },
    '& h4': {
      margin: 0,
      color: '#4F4F4F',
    },
  },
  settingsButton: {
    border: '1px solid #026FE4',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '25px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#026FE4',
    marginTop: ({ isMobile }) => (isMobile ? '20px' : '50px'),
    textTransform: 'inherit',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  tabs: {
    borderBottom: '1px solid #828282;',
  },
  tabsWrapper: {
    '& .tabButtons': {
      marginRight: '40px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '21px',
      lineHeight: '29px',
      color: '#828282',
      cursor: 'pointer',
      paddingBottom: '8px',
      background: 'transparent',
      border: 'none',
      maxWidth: '780px',
    },
  },
  activeTab: {
    color: '#6B54CF !important',
    borderBottom: '3px solid #6B54CF !important',
    transition: '',
  },
  tabsContent: {
    border: '1px solid #BDBDBD',
    boxSizing: 'border-box',
    borderRadius: '8px',
    marginTop: '15px',
    padding: '20px',
    paddingBottom: '40px',
  },
  inputGroups: {
    marginBottom: '18px',
    '& h4': {
      margin: 0,
      color: '#4F4F4F',
    },
    '& .disabledFields': {
      background: '#F2F2F2',
      border: '1px solid #828282',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '150%',
      color: '#4F4F4F',
      padding: '8px',
      marginTop: '8px',
      width: 'fit-content',
    },
  },
  textArea: {
    background: '#F2F2F2',
    border: '1px solid #828282',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '100%',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    color: '#4F4F4F',
    padding: '10px',
    marginTop: '10px',
    minHeight: '100px',
    '&:focus': {
      border: '1px solid #3f51b5',
    },
  },
  gooleMeet: {
    display: 'flex',
    gap: ({ isMobile }) => (isMobile ? '0' : '20px'),
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    '& p': {
      color: '#026FE4 !important',
      fontWeight: '600 !important',
      paddingLeft: '22px !important',
      paddingRight: '22px !important',
    },
    '& p:nth-child(2)': {
      border: '1.5px solid #026FE4 !important',
    },
  },
  profileEdit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
  },
  bookingInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '25px 0px',
    flexDirection: 'column',
    '& button': {
      marginTop: '10px',
      fontSize: '18px',
      paddingLeft: '20px',
      paddingRight: '20px',
      marginBottom: '30px',
    },
    '& p': {
      fontWeight: 'normal',
      fontSize: '21px',
      lineHeight: '29px',
      color: '#495057',
      textAlign: 'center',
    },
  },
  timSlotHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 28px',
    '& button': {
      height: 'fit-content',
      marginTop: '0',
      lineHeight: '20px',
      fontSize: '16px',
      display: ({ bookingMediaQuery }) =>
        bookingMediaQuery ? 'none' : 'block',
    },
    '& .rightWrapper': {
      alignItems: 'center',
      display: 'flex',
      gap: '20px',
      position: 'relative',
      '& img': {
        cursor: 'pointer',
      },
    },
    '& h2': {
      color: '#495057',
    },
  },
  timeSlotData: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '15px',
    paddingLeft: '22px',
    paddingRight: '22px',
    flexFlow: 'row wrap',
    // borderBottom: '1px solid #9A9A9A',
    '& span': {
      marginRight: '5px',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#026FE4',
    },
    '& div': {
      fontWeight: 'bold',
      fontSize: '21px',
      lineHeight: '29px',
      color: '#495057',
      marginRight: '35px',
    },
    '& p': {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '25px',
      color: '#4F4F4F',
      marginLeft: ({ bookingMediaQuery }) => (bookingMediaQuery ? '55px' : 0),
    },
  },
  dropDown: {
    flexDirection: 'column',
    background: '#495057',
    alignItems: 'flex-start',
    color: '#ffffff',
    borderRadius: '4px',
    display: 'flex',
    gap: '0px',
    position: 'relative',
    '&:after, &:before': {
      bottom: '100%',
      left: '50%',
      border: 'solid transparent',
      content: '""',
      height: '0',
      width: '0',
      position: 'absolute',
      pointerEvents: 'none',
    },
    '&:before': {
      marginLeft: '19px',
      borderWidth: '10px',
      borderBottomColor: '#495057',
    },
    '& button': {
      background: 'transparent',
      border: 'none',
      color: 'white',
      fontSize: '16px',
      width: '98px',
      fontWeight: 'bold',
      padding: '15px 15px',
      lineHeight: '19px',
      display: 'flex',
      textAlign: 'left',
      fontFamily: 'Open Sans',
      cursor: 'pointer',
    },
    '& button:nth-child(2)': {
      paddingTop: '10px !important',
    },
  },
  threeDotsWrapper: {
    cursor: 'pointer',
    '&:hover > div': {
      visibility: 'visible',
    },
  },
  dropDownWrapper: {
    visibility: 'hidden',
    position: 'absolute',
    right: '-5px',
    top: '24px',
    paddingTop: '24px',
  },
  timeslotH2: {
    padding: '0px 28px',
    color: '#495057',
  },
  hr: {
    width: ({ bookingMediaQuery }) => (bookingMediaQuery ? '100%' : '95%'),
  },
  threeDotsWrapperInner: {
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    background: '#F2F7FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  meetingsDetails: {
    display: 'flex',
    padding: '0px 32px',
    paddingBottom: '20px',
    alignItems: ({ isMobile }) => (isMobile ? 'center' : 'flex-start'),
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
    justifyContent: 'space-between',
    '& div': {
      '& p': {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '150%',
        color: '#495057',
        display: 'flex',
        gap: '4px',
        flexFlow: 'row wrap',
        '& label': {
          fontWeight: 'normal',
          fontSize: '16px',
          lineHeight: '150%',
          color: '#495057',
        },
        '& .meeting-person-name': {
          fontWeight: 'bold',
          fontSize: '18px',
          lineHeight: '25px',
          color: '#495057',
        },
        '& .meeting-link': {
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '25px',
          color: '#026FE4',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
  nomeetings: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    flexDirection: 'column',
    padding: '50px 0px',
    '& p': {
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '25px',
      textAlign: 'center',
      color: '#495057',
      margin: 0,
    },
  },
  mobileAddTimeSlotBtn: {
    padding: '30px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #9A9A9A',
    '& button': {
      margin: 0,
    },
  },
  securityFields: {
    marginTop: '10px',
    '& div': {
      '& input': {
        padding: '11.5px 14px',
      },
    },
  },
  disabledInputsBG: {
    background: '#F2F2F2 !important',
  },
  personalInfoDisabled: {
    '& div': {
      '& input': {
        padding: 0,
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '150%',
        color: '#4F4F4F',
      },
    },
    '& fieldset': {
      border: 'none',
    },
  },
  backdrop: {
    zIndex: 999999,
    color: '#fff',
  },
  buttonDiv: {
    minWidth: '150px',
  },
  summaryStyle: {
    color: '#4F4F4F',
  },
}));

export default useStyles;
